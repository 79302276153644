import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

const Layout = ({ children, pageData }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      ogImage: contentfulOgImage {
        ogImage {
          fixed(height: 630, width: 1200, quality: 75) {
            src
          }
        }
      }
    }
  `)

  const { title, description, siteUrl } = data.site.siteMetadata
  const realDescription = pageData.description || description
  const realTitle = pageData.title || title
  const ogImage = data.ogImage.ogImage.fixed.src

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{realTitle}</title>
        <meta name="description" content={realDescription} />
        {/* OG */}
        <meta property="og:locale" content="fi_FI" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={realTitle} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:site_name" content={title} />
        <meta property="og:description" content={realDescription} />
        <meta property="og:image" content={ogImage} />
        <meta name="twitter:site" content={title} />
        <meta name="twitter:card" content="summary_large_image" />
        <html lang="fi" />
        {/* <!-- Global site tag (gtag.js) - Google Ads: 786361316 --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-786361316"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'AW-786361316');{" "}`}
        </script>
        {/* <!-- Event snippet for Page view conversion page --> */}
        <script>
          {
            "gtag('event', 'conversion', {'send_to': 'AW-786361316/gzeuCMG1__sBEOTX-_YC'})"
          }
        </script>
      </Helmet>
      <main>{children}</main>
    </>
  )
}

export default Layout
